<template>
  <div class="record">
    <h5header :name="$t('withdraw.desc32')" :right="false" />
    <div class="record-con">
      <div class="record-top">
        <div class="tips">{{ $t("withdraw.desc34") }}</div>
        <div class="text">-{{ common.cutXiaoNum1(info.amount) }} {{ info.feeCoinName }}</div>
      </div>
      <div class="record-list">
        <div class="list-info flex">
          <div>{{ $t("record.desc16") }}</div>
          <p>
            {{ info.orderNo
            }}<img src="../../public/images/new/copy.png" alt="" @click="copy($event, info.orderNo)" />
          </p>
        </div>
        <div class="list-info flex">
          <div>{{ $t("withdraw.desc37") }}</div>
          <p class="flex">
            {{ dealAuditStatus(info.auditStatusCode, info.status) }}
          </p>
        </div>
        <div class="list-info flex">
          <div>{{ $t("withdraw.desc35") }}</div>
          <p>{{ info.chainName }}</p>
        </div>
        <div class="list-info flex">
          <div>{{ $t('withdraw.desc38') }}</div>
          <p>{{ info.txHash && info.txHash.length > 6 ? dealAddress(info.txHash) : '--' }}<img
              src="../../public/images/new/copy.png" alt="" @click="copy($event, info.txHash)" /></p>
        </div>
        <div class="list-info flex">
          <div>{{ $t("withdraw.desc5") }}</div>
          <p>
            {{ info.address
            }}<img src="../../public/images/new/copy.png" alt="" @click="copy($event, info.address)" />
          </p>
        </div>
        <div class="list-info flex">
          <div>{{ $t('withdraw.desc7') }}</div>
          <p>{{ common.cutXiaoNum1(info.fee) }} DU</p>
        </div>
        <div class="list-info flex">
          <div>{{ $t("record.desc5") }}</div>
          <p>{{ info.datetime }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import h5header from "@/componets/h5header.vue";
import Clipboard from "@/utils/libs/clipboard";
export default {
  components: {
    h5header,
  },
  data() {
    return {
      info: {},
    };
  },
  mounted() {
    this.info = JSON.parse(localStorage.getItem("withdrawRecordInfo"));
    this.info.datetime = this.common.formatDate(this.info.txDate);
  },
  methods: {
    dealAuditStatus(auditStatusCode, status) {
      if (auditStatusCode === 1) {
        switch (status) {
          case 0:
            return this.$t("withdraw.desc39");
          case 1:
            return this.$t("withdraw.desc40");
          case 2:
            return this.$t("withdraw.desc41");

          default:
            break;
        }
      } else {
        switch (auditStatusCode) {
          case 0:
            return this.$t("withdraw.desc24");
          case 2:
            return this.$t("withdraw.desc25");
        }
      }
    },

    copy(e, text) {
      // @click="copy($event,googleRandom.secretKey)"
      let that = this;
      Clipboard(e, text).then((res) => {
        that.$message.success(this.$t("planet.desc61"));
      });
    },
    dealAddress(str) {
      if (str && str.length > 18) {
        return (
          str.substring(0, 6) +
          "...." +
          str.substring(str.length - 6, str.length)
        );
      } else {
        return str;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.record {
  padding: 0 16px;
  min-height: 100vh;
  background: url("../../public/images/new/h5bg.png") center no-repeat;
  background-size: 100% 100%;

  .record-top {
    margin-bottom: 30px;

    .tips {
      font-size: 14px;
      font-weight: 400;
      color: #fff;
      text-align: center;
      margin-bottom: 12px;
    }

    .text {
      font-size: 24px;
      font-weight: 500;
      color: #fff;
      text-align: center;
    }
  }

  .record-con {
    padding: 56px 0;

    .record-list {
      padding: 0 16px;
      margin-top: 12px;
      border-radius: 8px;
      background: #181818;

      .list-info {
        justify-content: space-between;
        padding: 16px 0;
        border-bottom: 1px solid rgba(255, 255, 255, 0.06);
        width: 100%;

        &:last-child {
          border-bottom: 0;
        }

        div {
          max-width: 22%;
          flex: 1;
          font-size: 13px;
          color: rgba(255, 255, 255, .6);
        }

        p {
          max-width: 60%;
          display: flex;
          align-items: center;
          flex: 1;
          text-align: right;
          font-size: 13px;
          color: #fff;
          justify-content: flex-end;

          img {
            width: 12px;
            height: 12px;
            margin-top: 3px;
            margin-left: 8px;
          }
        }
      }
    }
  }
}
</style>
